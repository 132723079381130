import * as React from "react"
import Layout from "../components/layout"

import Helmet from "react-helmet";

export default function Index() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sandbox</title>
        <meta name="description" content="Whatever is on this page, I'm working on it." />
        <meta name="image" content="https://awmcphee.ca/headshot.jpg" />
        <meta property="og:image" content="https://awmcphee.ca/headshot.jpg"/>
        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:site" content="@ksituan"/>
        <meta name="twitter:title" content="Alex's Sandbox"/>
        <meta name="twitter:description" content="Whatever is on this page, I'm working on it."/>
        <meta name="twitter:image" content="https://awmcphee.ca/headshot.jpg"/>
      </Helmet>
      <h1>Sandbox</h1>
      <p>This page occasionally used for test projects.</p>
      <p>Formerly the home of the <a href="https://canadianpolling.ca/">Polling Canada</a> codebase, now live and functioning at its own domain.</p>
    </Layout>
  )
}